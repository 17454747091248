.user-comments {
  height: 100%;
  margin: 0 -15px;
  overflow-y: auto;
  padding: 0 15px;
  & .add-border {
    border-top: 1px solid #323842;
  }
  & .date {
    color: #a7acb4;
  }
  & .para {
    color: #a7acb4;
  }
  .form-group{
    position: sticky;
    top: 0;
    background-color: var(--secondary-800);
    z-index: 9;
  }
}
