.permissions-txt {
  margin: -8px;
  li {
    list-style-type: none;
    background-color: var(--secondary-600);
    border-radius: 4px;
    font-size: $fs-14;
    font-weight: $fw-600;
    padding: 0 13px;
    margin: 8px;
    text-transform: uppercase;
  }
  @include media-max(md) {
    margin: -3px;
    li {
      font-size: $fs-13;
      margin: 3px;
    }
  }
}
