.breadcrumb-main {
  display: flex;
}
.data-table {
  position: relative;
  .data-table-header {
    margin-bottom: 15px;
    .left .bulk-action + .bulk-action {
      margin-left: 20px;
    }
    .right * + * {
      margin-left: 20px;
    }
    .search-box {
      width: 285px;
    }
    .bulk-action {
      i {
        top: 4px;
      }
      span:not(.select__indicator-separator) {
        margin-right: 9px;
      }
    }
  }
  .dropdown-menu .dropdown-item {
    margin:0px;
  }
  .data-table-tabs {
    list-style-type: none;
    margin: 0;
    padding: 0;
    border-bottom: 1px solid var(--secondary-700);
    overflow-x: auto;
    li {
      font-size: $fs-13;
      color: var(--secondary-400);
      font-weight: $fw-500;
      cursor: pointer;
      min-width: 140px;
      text-align: center;
      border-bottom: 2px solid transparent;
      padding: 3px 5px;
      &.active {
        border-bottom-color: var(--secondary-100);
        font-weight: $fw-600;
        color: var(--secondary-100);
      }
    }
  }
  thead {
    border-bottom: 1px solid var(--secondary-700);
    text-transform: uppercase;
    .checkbox {
      width: 65px;
    }
    th {
      font-size: $fs-14;
      line-height: 1.14;
      color: var(--secondary-400);
      font-weight: $fw-700;
      padding: 10px 16px;
      vertical-align: middle;
      height: 48px;
      &:not(:first-child):last-child {
        text-align: right;
      }
      span {
        cursor: pointer;
      }
      i {
        font-size: $fs-20;
        vertical-align: middle;
      }
    }
  }
  tbody {
    border-bottom: 1px solid var(--secondary-700);
    tr {
      & ~ tr {
        border-top: 1px solid var(--secondary-700);
      }
    }
    td {
      font-size: $fs-16;
      color: var(--secondary-200);
      line-height: 1.63;
      font-weight: $fw-500;
      padding: 16px;
      vertical-align: middle;
      &:not(:first-child):last-child {
        text-align: right;
      }
    }
  }
  @include media-max(md) {
    thead th {
      height: 40px;
      padding: 5px 10px;
      white-space: nowrap;
    }
    tbody td {
      font-size: $fs-14;
      padding: 10px;
    }
    .data-table-header {
      .right {
        margin-top: 15px;
      }
    }
    .data-table-tabs{
      li{
        min-width: 100px;
      }
    }
  }
  @include media-max(sm) {
    .data-table-header {
      .search-box {
        width: auto;
      }
    }
  }  
}
