.common-tabs {
  flex-wrap: nowrap;
  border-bottom: none;
  margin: 0 -12px 22px -12px;
  .nav-item {
    min-width: 175px;
    padding: 0 12px;
    .nav-link {
      display: block;
      width: 100%;
      border: none;
      background-color: transparent;
      letter-spacing: 0.4px;
      font-weight: $fw-600;
      font-size: $fs-16;
      color: var(--secondary-200);
      border-bottom: 2px solid var(--secondary-700);
      line-height: 1.3;
      padding: 10px 5px;
      cursor: pointer;
      text-align: center;
      text-transform: uppercase;
      &.active {
        color: $primary-300;
        border-color: $primary-300;
      }
    }
  }
  @include media-max(md) {
    margin: 0 -4px 22px -4px;
    .nav-item {
      min-width: auto;
      width: 100%;
      padding: 0 4px;
      .nav-link {
        font-size: $fs-14;
      }
    }
  }
}

.article-tabs {
  flex-wrap: nowrap;
  border-bottom: none;
  margin: 10px -8px 22px -8px;
  .nav-item {
    width: 100%;
    padding: 0 8px;
    .nav-link {
      display: block;
      width: 100%;
      border: none;
      background-color: transparent;
      letter-spacing: 0.4px;
      font-size: $fs-12 + 1;
      color: var(--secondary-400);
      border-bottom: 1px solid transparent;
      line-height: 1.3;
      padding: 7px 0;
      cursor: pointer;
      text-align: center;
      &.active {
        color: $primary-200;
        border-color: $primary-200;
      }
    }
  }
  @include media-max(md) {
    margin: 0 -4px 22px -4px;
    .nav-item {
      min-width: auto;
      width: 100%;
      padding: 0 4px;
      .nav-link {
        font-size: $fs-14;
      }
    }
  }
}
.article-content {
  .tab-pane {
    max-height: 180px;
    overflow-y: auto;
  }
}

.seo-tab {
  .title-txt {
    color: var(--secondary-400);
    font-size: $fs-12;
    font-weight: $fw-700;
    letter-spacing: 0.5px;
    margin-bottom: 23px;
  }
  .m-title {
    font-size: $fs-20 + 1;
    margin-bottom: 15px;
  }
  .radio-group {
    .form-label {
      width: 95px;
    }
    .form-check + .form-check {
      margin-left: 15px;
    }
  }
  .social {
    display: grid;
    grid-template-columns: 380px calc(100% - 400px);
    grid-gap: 20px;
    margin-top: 35px;
    .left {
      h3 {
        text-transform: uppercase;
        font-size: $fs-14;
        letter-spacing: 0.233333px;
        color: var(--secondary-200);
      }
      .img-box {
        height: 230px;
        border-radius: 8px;
        overflow: hidden;
        background-color: var(--secondary-700);
        position: relative;
        i {
          font-size: 65px;
          color: var(--secondary-500);
        }
      }
      .show-box {
        background-color: var(--secondary-800);
        border-radius: 0px 0px 8px 8px;
        padding: 18px 14px 9px 14px;
        margin-top: -9px;
        p {
          margin: 0;
          font-size: $fs-14;
          color: var(--secondary-400);
        }
      }
    }
    .right {
      padding-top: 35px;
    }
    & + .social {
      margin-top: 65px;
    }
  }
  .preview {
    background-color: var(--secondary-800);
    border-radius: 16px;
    display: grid;
    grid-template-columns: 145px calc(100% - 160px);
    grid-gap: 20px;
    padding: 20px;
    .img {
      height: 90px;
      border-radius: 12px;
      overflow: hidden;
      background-color: var(--secondary-700);
      font-size: $fs-30;
    }
    .p-right {
      h2 {
        font-size: $fs-18;
        color: var(--secondary-200);
        margin-bottom: 8px;
      }
      p {
        margin: 0;
        font-size: $fs-14;
        span {
          color: $primary-300;
        }
      }
    }
    &.t {
      max-width: 670px;
      .p-right {
        h2 {
          font-size: $fs-16;
        }
      }
    }
    &.m {
      border-radius: 5px;
      max-width: 400px;
      padding: 15px;
      grid-gap: 15px;
      .img {
        border-radius: 5px;
      }
      .p-right {
        h2 {
          font-size: $fs-14;
        }
      }
    }
  }
  @include media-max(lg) {
    .social {
      grid-template-columns: 300px calc(100% - 315px);
      grid-gap: 15px;
      .left .img-box {
        height: 200px;
      }
    }
  }
  @include media-max(md) {
    .social {
      display: block;
      .right {
        padding-top: 20px;
      }
      & + .social {
        margin-top: 25px;
      }
    }
    .preview {
      display: block;
      padding: 15px;
      .img {
        height: 160px;
        margin-bottom: 15px;
      }
    }
  }
  @include media-max(sm) {
    .radio-group {
      .form-label {
        width: auto;
      }
      .form-check {
        margin-top: 10px;
        & + .form-check {
          margin-left: 0;
        }
      }
    }
  }
}
