.article-preview {
  width: 100%;
  max-width: 1246px;
  .device {
    list-style-type: none;
    li {
      cursor: pointer;
      text-align: center;
      margin: 0 22px;
      i {
        font-size: $fs-30 + 4;
        color: $white;
        padding: 8px;
        border: 1px solid var(--secondary-600);
        border-radius: 4px;
        display: block;
      }
      span {
        display: block;
        color: var(--secondary-400);
        font-size: $fs-14;
        margin-top: 3px;
      }
      &.active {
        span,
        i {
          color: $primary-300;
        }
      }
      @media(max-width: 479px) {
        margin: 0 10px;
      }
    }
  }
  .preview {
    height: calc(100% - 100px);
    iframe {
      width: 100%;
      max-width: 375px;
      height: 100%;
      &.t{
        max-width: 768px;
      }
      &.d{
        max-width: 1366px;
      }
    }
  }
}
