::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--secondary-700);
  border-radius: 4px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--secondary-500);
  border-radius: 4px;
}