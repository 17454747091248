@font-face {
  font-family: "noto_sans_display";
  src: url("../../fonts/notosansdisplay-regular.woff2") format("woff2"), url("../../fonts/notosansdisplay-regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "noto_sans_display";
  src: url("../../fonts/notosansdisplay-medium.woff2") format("woff2"), url("../../fonts/notosansdisplay-medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "noto_sans_display";
  src: url("../../fonts/notosansdisplay-semibold.woff2") format("woff2"), url("../../fonts/notosansdisplay-semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "noto_sans_display";
  src: url("../../fonts/notosansdisplay-bold.woff2") format("woff2"), url("../../fonts/notosansdisplay-bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "noto_sans_display";
  src: url("../../fonts/notosansdisplay-black.woff2") format("woff2"), url("../../fonts/notosansdisplay-black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}
