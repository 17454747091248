.edit-profile {
  .img {
    height: 125px;
    width: 125px;
    background-color: var(--secondary-800);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    .profile-icon {
      position: absolute;
      top: 33px;
      left: -8px;
      font-size: 60px;
      margin-left: 40px;
      color: var(--secondary-400);
    }
  }
  .button-space {
    margin-left: 10px;
  }
  .add-border {
    border-top: 1px solid var(--secondary-800);
    margin-top: 24px;
    padding-top: 24px;
  }
  .pan-img {
    height: 195px;
    border-radius: 12px;
    background-color: var(--secondary-800);
    font-size: 65px;
    margin-bottom: 12px;
    overflow: hidden;
    color: var(--secondary-500);
  }
  .light-font {
    color: #757982;
    font-size: 12px;
    font-weight: 600;
  }
  .title-font {
    color: #a7acb4;
    font-size: 12px;
    font-weight: 600;
  }
  .social-btn {
    border: 1px solid var(--secondary-500);
    color: $primary-300;
    padding: 8px;
    margin-top: 28px;
    font-size: 22px;
  }
  .add-media {
    font-size: $fs-14;
    i {
      font-size: $fs-16;
      margin-right: 5px;
      vertical-align: middle;
    }
  }
  @include media-max(xl) {
    .img {
      height: 100px;
      width: 100px;
      border-radius: 50%;
      .profile-icon {
        top: 30px;
        left: -11px;
        font-size: 40px;
      }
    }
  }
  @include media-max(lg) {
    .img {
      height: 100px;
      width: 100px;
      border-radius: 50%;
      .profile-icon {
        top: 30px;
        left: -11px;
        font-size: 40px;
      }
    }
  }
  @include media-max(md) {
    .img {
      height: 140px;
      width: 140px;
      border-radius: 50%;
      .profile-icon {
        top: 40px;
        left: -5px;
        font-size: 60px;
      }
    }
  }
  @include media-max(sm) {
    .img {
      height: 120px;
      width: 120px;
      border-radius: 50%;
      .profile-icon {
        top: 35px;
        left: -8px;
        font-size: 50px;
      }
    }
  }
}
