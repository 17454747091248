.badge {
  font-size: $fs-12;
  line-height: 10px;
  border-radius: 4px;
  padding: 4px 12px;
  font-weight: 600;
  &.bg-success {
    background-color: #68c69e !important;
    color: #1b593f;
  }
  &.bg-danger {
    background-color: #e68a8a !important;
    color: #993a3a;
  }
  &.bg-warning {
    background-color: #ffd580 !important;
    color: #7f5500;
  }
  &.bg-secondary {
    background-color: #757982 !important;
    color: #23272e;
  }
  &.bg-info {
    background-color: #978AE6 !important;
    color: #483A99;
  }
  &.bg-warning-light {
    background-color: #FFAD80 !important;
    color: #734E3A;
  }
  &.bg-danger-light {
    // TODO:
    background-color: #FFB5B5 !important;
    color: #9B0000;
  }
}
