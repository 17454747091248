@mixin media-max($breakpoint) {
  @if map-has-key($grid-breakpoints, $breakpoint) {
    // Get the breakpoint value
    $breakpoint-value: map-get($grid-breakpoints, $breakpoint);
    // Write the media query
    @media (max-width: $breakpoint-value) {
      @content;
    }
    // If the breakpoint doesn't exist in the map
  } @else {
    // Log a warning
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

@mixin media-min($breakpoint) {
  @if map-has-key($grid-breakpoints, $breakpoint) {
    // Get the breakpoint value
    $breakpoint-value: map-get($grid-breakpoints, $breakpoint);
    // Write the media query
    @media (min-width: $breakpoint-value) {
      @content;
    }
    // If the breakpoint doesn't exist in the map
  } @else {
    // Log a warning
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}
