.dropdown-menu {
  background-color: var(--secondary-700);
  border-radius: 12px;
  min-width: 205px;
  padding: 10px 0;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.10495);
  &:before {
    content: "";
    position: absolute;
  }
  &.up-arrow {
    margin-top: 10px;
    &:before {
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid var(--secondary-700);
      bottom: 100%;
      left: 15px;
    }
  }
  &.left-arrow {
    margin-top: 10px;
    &:before {
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent; 
      border-right:10px solid var(--secondary-700); 
      right: 100%;
      top: 15px;
    }
  }
  .dropdown-item {
    color: var(--secondary-100);
    background-color: transparent;
    font-size: $fs-13;
    padding: 10px 20px;
    i {
      font-size: $fs-20;
      vertical-align: -2px;
      margin-right: 9px;
    }
    &:hover {
      color: $primary-300;
    }
  }
  &.big {
    .dropdown-item {
      font-size: $fs-16;
    }
  }
}
