.modal-content {
  background-color: var(--secondary-800);
  border-radius: 10px;
  .modal-header {
    border-bottom: 1px solid var(--secondary-700);
  }
  .modal-body {
    padding: 22px;
  }
  .modal-footer {
    border-top: 1px solid var(--secondary-700);
  }
}
.image-popup .carousel-inner{
  max-height: calc(100vh - 100px);
}
