.form-group {
  margin-bottom: 22px;
  position: relative;
  .form-label {
    font-size: $fs-12;
    font-weight: $fw-500;
    letter-spacing: 0.2px;
    color: var(--secondary-100);
    line-height: 1.83;
    margin-bottom: 8px;
    span {
      color: var(--secondary-400);
      margin-left: 5px;
    }
    i {
      font-size: $fs-20;
      margin-left: 5px;
      vertical-align: -3px;
    }
  }
  .form-control {
    padding: 8px 10px 8px 12px;
    border-radius: 8px !important;
    background-color: var(--secondary-700);
    border: none;
    box-shadow: inset 0 0 0 0 var(--secondary-500);
    color: var(--secondary-300);
    font-size: $fs-14;
    background-image: none;
    &::-ms-expand {
      display: none;
    }
    &:not(:read-only):focus {
      color: var(--secondary-300);
      box-shadow: inset 0 0 0 1px var(--secondary-500);
    }
    &.error {
      box-shadow: inset 0 0 0 1px $red-300;
      border-color: #e64343;
    }
    &:disabled {
      color: var(--secondary-500);
    }
    &.form-control-sm,
    &.form-select-sm {
      padding: 4px 10px;
      font-size: $fs-13;
    }
  }
  &.only-border .form-control {
    box-shadow: inset 0 0 0 1px var(--secondary-500);
    background-color: transparent;
    border-radius: 4px !important;
  }
  select.form-control,
  select.form-control.form-control-sm {
    padding-right: 32px !important;
  }
  .input-group {
    .form-control {
      padding-right: 40px;
    }
    .icon-right {
      color: var(--secondary-500);
      position: absolute;
      right: 0;
      top: 0;
      z-index: 9;
    }
    .input-group-text {
      background-color: var(--secondary-700);
      border: none;
      color: var(--secondary-300);
      font-size: $fs-14;
      border-radius: 8px 0 0 8px;
      border-right: 2px solid var(--secondary-500);
      & ~ .form-control {
        padding-right: 10px;
        border-radius: 0 8px 8px 0 !important;
      }
    }
    &.padding-normal {
      .form-control {
        padding: 8px 10px 8px 12px;
      }
    }
    &.border-left{
      .form-control {
        border-radius: 8px 0 0 8px !important;
      }
    }
  }
  .icon-chevron-down {
    position: absolute;
    font-size: $fs-22;
    right: 10px;
    top: 38px;
    pointer-events: none;
  }

  textarea.form-control {
    height: 76px;
  }
  @include media-max(md) {
    margin-bottom: 15px;
    .form-label {
      margin-bottom: 3px;
    }
    .icon-chevron-down {
      top: 33px;
    }
  }
}
.invalid-feedback {
  display: block;
  padding: 6px 1px 0;
  color: $red-300;
  font-size: $fs-12;
  font-weight: $fw-500;
  margin: 0;
  line-height: 1.33;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  background: url('../../images/closefilledblue.svg') no-repeat 50% 50%;
}